import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from "@angular/material/dialog";
import { DialogProgressComponent } from "./dialog-progress/dialog-progress.component";
import { DialogSuccessComponent } from "./dialog-success/dialog-success.component";
import { DialogErrorComponent } from "./dialog-error/dialog-error.component";
import { DialogConfirmComponent } from "./dialog-confirm/dialog-confirm.component";
import { MaterialModule } from "src/app/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { WmmPopupComponent } from './wmm-popup/wmm-popup.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule, MatRippleModule, MatSidenavModule, MatToolbarModule } from "@angular/material";
import { GenerateDnComponent } from './generate-dn/generate-dn.component';
import { MissingPopupComponent } from './missing-popup/missing-popup.component';
import { ExpiryComponent } from './expiry/expiry.component';
import { WrongBilledComponent } from './wrong-billed/wrong-billed.component';
import { RemoveBounceMedComponent } from './remove-bounce-med/remove-bounce-med.component';
import { RemoveMedConfirmComponent } from './remove-med-confirm/remove-med-confirm.component';
import { SearchByRetailerComponent } from './search-by-retailer/search-by-retailer.component';
import { SearchByOrderComponent } from './search-by-order/search-by-order.component';
import { SearchByInvoiceComponent } from './search-by-invoice/search-by-invoice.component';
import { BlockUserComponent } from './block-user/block-user.component';
import { ImageComponent } from './image/image.component';
import { UnblockUserComponent } from './unblock-user/unblock-user.component';
import { CommentComponent } from './comment/comment.component';
import { LogisticsCollectComponent } from './logistics-collect/logistics-collect.component';
import { CollectRetailerOutstandingComponent } from './collect-retailer-outstanding/collect-retailer-outstanding.component';
import { OutstandingPaymentHistoryComponent } from './outstanding-payment-history/outstanding-payment-history.component';
import { OutstandingDeRetailerInvoiceComponent } from './outstanding-de-retailer-invoice/outstanding-de-retailer-invoice.component';
import { OutstandingBillingInvoiceComponent } from './outstanding-billing-invoice/outstanding-billing-invoice.component';
import { ConnectionLostComponent } from './connection-lost/connection-lost.component';
import { ConfirmDisablePlatformComponent } from './confirm-disable-platform/confirm-disable-platform.component';
import { ConfirmSingleSoCancelComponent } from './confirm-single-so-cancel/confirm-single-so-cancel.component';
import { ConfirmMultipleSoCancelComponent } from './confirm-multiple-so-cancel/confirm-multiple-so-cancel.component';
import { ConfirmRetrieveComponent } from './confirm-retrieve/confirm-retrieve.component';
import { GeneratePoComponent } from './generate-po/generate-po.component';
import { ConfirmMedicineDeleteComponent } from './confirm-medicine-delete/confirm-medicine-delete.component';
import { CommonConfirmComponent } from './common-confirm/common-confirm.component';
import { AdnUploadErrorsComponent } from './adn-upload-errors/adn-upload-errors.component';
import { AdnUploadCsvComponent } from './adn-upload-csv/adn-upload-csv.component';
import { LiveSiOrderComponent } from './live-si-order/live-si-order.component';
import { AddMedicineSiComponent } from './add-medicine-si/add-medicine-si.component';
import { PastSiOrderComponent } from './past-si-order/past-si-order.component';
import { PrintBillModule } from '../print-bill/print-bill.module';
import { AddNewMedicineToMapComponent } from './add-new-medicine-to-map/add-new-medicine-to-map.component';
import { AddNewCompositionComponent } from './add-new-composition/add-new-composition.component';
import { AddNewManufactureComponent } from './add-new-manufacture/add-new-manufacture.component';
import { AddNewDiseaseComponent } from './add-new-disease/add-new-disease.component';
import { AdnMapConfirmComponent } from './adn-map-confirm/adn-map-confirm.component';
import { PrintBillNewModule } from '../print-bill-new/print-bill-new.module';
import { QRCodeModule } from 'angularx-qrcode';
import { ErrorListComponent } from './error-list/error-list.component';
import { UploadCreditErrorComponent } from './upload-credit-error/upload-credit-error.component';
import { ConfirmComponent } from "src/app/shared/dialog/confirm/confirm.component";
import { CreateTicketComponent } from './create-ticket/create-ticket.component';
import { MaterialFileInputModule } from "ngx-material-file-input";
import { TicketInstrutionsComponent } from './ticket-instrutions/ticket-instrutions.component';
import { CreateHelpExecutiveComponent } from './create-help-executive/create-help-executive.component';
import { MultipleImagesComponent } from './multiple-images/multiple-images.component';
import { UploadCreditBillErrorComponent } from './upload-credit-bill-error/upload-credit-bill-error.component';
import { ConfirmReasonComponent } from 'src/app/shared/dialog/confirm-reason/confirm-reason.component';
import { AssignExpiryComponent } from './assign-expiry/assign-expiry.component';
import { CaCollectionComponent } from './ca-collection/ca-collection.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MappingApproveConfirmComponent } from './mapping-approve-confirm/mapping-approve-confirm.component';
import { WarehouseGenericConfirmComponent } from './warehouse-generic-confirm/warehouse-generic-confirm.component';
import { DrOpenItemsSlotFilterComponent } from "./dr-open-items-slot-filter/dr-open-items-slot-filter.component";
import { DrOpenItemsSpokeFilterComponent } from './dr-open-items-spoke-filter/dr-open-items-spoke-filter.component';
import { AddOpenMedicineWarehouseComponent } from './add-open-medicine-warehouse/add-open-medicine-warehouse.component';
import { AddPickItemComponent } from './add-pick-item/add-pick-item.component';
import { CollectSignedSlipComponent } from './collect-signed-slip/collect-signed-slip.component';
import { AddBannerComponent } from './add-banner/add-banner.component';
import { CollectAcknowledgementSlipComponent } from './collect-acknowledgement-slip/collect-acknowledgement-slip.component';
import { SendExpiryItmesToDnComponent } from './send-expiry-itmes-to-dn/send-expiry-itmes-to-dn.component';
import { AddItemsFilterComponent } from './add-items-filter/add-items-filter.component';
import { GenerateRdcnComponent } from './generate-rdcn/generate-rdcn.component';
import { CheckManualBillComponent } from './check-manual-bill/check-manual-bill.component';
import { GstResponseComponent } from './gst-response/gst-response.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { FinanceInvoiceDetailsComponent } from './finance-invoice-details/finance-invoice-details.component';
import { FinanceDistributorDetailsComponent } from './finance-distributor-details/finance-distributor-details.component';
import { FinanceDisputeComponent } from './finance-dispute/finance-dispute.component';
import { FinanceInvoiceListComponent } from './finance-invoice-list/finance-invoice-list.component';
import { FinanceDnComponent } from './finance-dn/finance-dn.component';
import { FinanceInvoiceAdjustComponent } from './finance-invoice-adjust/finance-invoice-adjust.component';
import { FinancePaymentHistoryComponent } from './finance-payment-history/finance-payment-history.component';
import { FinanceCnGeneratedComponent } from './finance-cn-generated/finance-cn-generated.component';
import { UploadWinnerListComponent } from './upload-winner-list/upload-winner-list.component';
import { FoundQuantityComponent } from './found-quantity/found-quantity.component';
import { SalesDashboardPopupComponent } from './sales-dashboard-popup/sales-dashboard-popup.component';
import { SaleMedicinesComponent } from './sale-medicines/sale-medicines.component';
import { CustomizeConfirmComponent } from './customize-confirm/customize-confirm.component';
import { SaveoLoaderComponent } from './saveo-loader/saveo-loader.component';
import { ManualCnPopupComponent } from './manual-cn-popup/manual-cn-popup.component';
import { RevertCNComponent } from './revert-cn/revert-cn.component';
import { ManageCnPopupComponent } from './manage-cn-popup/manage-cn-popup.component';
import { ViewPincodeMappingComponent } from './view-pincode-mapping/view-pincode-mapping.component';
import { ReverifyComponent } from './reverify/reverify.component';
import { LogisticsCoinsEditComponent } from './logistics-coins-edit/logistics-coins-edit.component';
import { LogisticsSessionsProceedComponent } from './logistics-sessions-proceed/logistics-sessions-proceed.component';
import { MapAdjustmentComponent } from './map-adjustment/map-adjustment.component';
import { CommonTableHeaderModule } from '../common-table-header/common-table-header.module';
import { CreateSessionConfirmComponent } from './create-session-confirm/create-session-confirm.component';
import { OutstandingPopupComponent } from './outstanding-popup/outstanding-popup.component';
import { PrintBillWarningComponent } from './print-bill-warning/print-bill-warning.component';
import { ViewBillComponent } from './view-bill/view-bill.component';
import { EwayBillComponent } from './eway-bill/eway-bill.component';

@NgModule({
  declarations: [
    DialogProgressComponent,
    DialogSuccessComponent,
    DialogErrorComponent,
    DialogConfirmComponent,
    WmmPopupComponent,
    GenerateDnComponent,
    MissingPopupComponent,
    ExpiryComponent,
    WrongBilledComponent,
    RemoveMedConfirmComponent,
    SearchByRetailerComponent,
    SearchByOrderComponent,
    SearchByInvoiceComponent,
    RemoveBounceMedComponent,
    RemoveMedConfirmComponent,
    BlockUserComponent,
    ImageComponent,
    UnblockUserComponent,
    CommentComponent,
    LogisticsCollectComponent,
    CollectRetailerOutstandingComponent,
    OutstandingPaymentHistoryComponent,
    OutstandingDeRetailerInvoiceComponent,
    OutstandingBillingInvoiceComponent,
    ConnectionLostComponent,
    ConfirmDisablePlatformComponent,
    ConfirmSingleSoCancelComponent,
    ConfirmMultipleSoCancelComponent,
    ConfirmRetrieveComponent,
    GeneratePoComponent,
    ConfirmMedicineDeleteComponent,
    ConfirmMedicineDeleteComponent,
    CommonConfirmComponent,
    AdnUploadErrorsComponent,
    AdnUploadErrorsComponent,
    LiveSiOrderComponent,
    AddMedicineSiComponent,
    PastSiOrderComponent,
    AddNewMedicineToMapComponent,
    AddNewCompositionComponent,
    AddNewManufactureComponent,
    AddNewDiseaseComponent,
    ConfirmComponent,
    ErrorListComponent,
    UploadCreditErrorComponent,
    ConfirmComponent,
    CreateTicketComponent,
    TicketInstrutionsComponent,
    CreateHelpExecutiveComponent,
    MultipleImagesComponent,
    UploadCreditBillErrorComponent,
    ConfirmReasonComponent,
    AssignExpiryComponent,
    CaCollectionComponent,
    MappingApproveConfirmComponent,
    WarehouseGenericConfirmComponent,
    DrOpenItemsSlotFilterComponent,
    DrOpenItemsSpokeFilterComponent,
    AddOpenMedicineWarehouseComponent,
    AddPickItemComponent,
    MultipleImagesComponent,
    AdnMapConfirmComponent,
    AdnUploadCsvComponent,
    CollectSignedSlipComponent,
    AddBannerComponent,
    CollectAcknowledgementSlipComponent,
    SendExpiryItmesToDnComponent,
    AddItemsFilterComponent,
    GenerateRdcnComponent,
    CheckManualBillComponent,
    GstResponseComponent,
    FinanceInvoiceDetailsComponent,
    FinanceDistributorDetailsComponent,
    FinanceDisputeComponent,
    FinanceInvoiceListComponent,
    FinanceDnComponent,
    FinanceInvoiceAdjustComponent,
    FinancePaymentHistoryComponent,
    FinanceCnGeneratedComponent,
    FoundQuantityComponent,
    SalesDashboardPopupComponent,
    SaleMedicinesComponent,
    CustomizeConfirmComponent,
    SaveoLoaderComponent,
    ManualCnPopupComponent,
    RevertCNComponent,
    UploadWinnerListComponent,
    ManageCnPopupComponent,
    ViewPincodeMappingComponent,
    ReverifyComponent,
    LogisticsCoinsEditComponent,
    LogisticsSessionsProceedComponent,
    MapAdjustmentComponent,
    CreateSessionConfirmComponent,
    OutstandingPopupComponent,
    PrintBillWarningComponent,
    ViewBillComponent,
    EwayBillComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule, 
    MatDialogModule, 
    MaterialModule,
    MatRippleModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    PrintBillModule,
    QRCodeModule,
    MaterialFileInputModule,
    MaterialFileInputModule,
    PrintBillNewModule,
    InfiniteScrollModule,
    NgxJsonViewerModule,
    CommonTableHeaderModule
  ],
  exports: [
    DialogProgressComponent,
    DialogSuccessComponent,
    DialogErrorComponent,
    DialogConfirmComponent,
    WmmPopupComponent,
    GenerateDnComponent,
    MissingPopupComponent,
    ExpiryComponent,
    WrongBilledComponent,
    RemoveMedConfirmComponent,
    SearchByRetailerComponent,
    SearchByOrderComponent,
    SearchByInvoiceComponent,
    RemoveBounceMedComponent,
    RemoveMedConfirmComponent,
    BlockUserComponent,
    ImageComponent,
    UnblockUserComponent,
    CommentComponent,
    LogisticsCollectComponent,
    CollectRetailerOutstandingComponent,
    OutstandingPaymentHistoryComponent,
    OutstandingDeRetailerInvoiceComponent,
    OutstandingBillingInvoiceComponent,
    ConnectionLostComponent,
    ConfirmDisablePlatformComponent,
    ConfirmSingleSoCancelComponent,
    ConfirmMultipleSoCancelComponent,
    ConfirmRetrieveComponent,
    GeneratePoComponent,
    ConfirmMedicineDeleteComponent,
    ConfirmMedicineDeleteComponent,
    CommonConfirmComponent,
    AdnUploadErrorsComponent,
    AdnUploadErrorsComponent,
    LiveSiOrderComponent,
    AddMedicineSiComponent,
    PastSiOrderComponent,
    AddNewMedicineToMapComponent,
    AddNewCompositionComponent,
    AddNewManufactureComponent,
    AddNewDiseaseComponent,
    ConfirmComponent,
    ErrorListComponent,
    UploadCreditErrorComponent,
    ConfirmComponent,
    CreateTicketComponent,
    TicketInstrutionsComponent,
    CreateHelpExecutiveComponent,
    MultipleImagesComponent,
    UploadCreditBillErrorComponent,
    ConfirmReasonComponent,
    AssignExpiryComponent,
    CaCollectionComponent,
    MappingApproveConfirmComponent,
    WarehouseGenericConfirmComponent,
    DrOpenItemsSlotFilterComponent,
    DrOpenItemsSpokeFilterComponent,
    AddOpenMedicineWarehouseComponent,
    AddPickItemComponent,
    MultipleImagesComponent,
    AdnMapConfirmComponent,
    AdnUploadCsvComponent,
    CollectSignedSlipComponent,
    AddBannerComponent,
    CollectAcknowledgementSlipComponent,
    AddItemsFilterComponent,
    GenerateRdcnComponent,
    GstResponseComponent,
    FinanceInvoiceDetailsComponent,
    FinanceDisputeComponent,
    FinanceInvoiceListComponent,
    FinanceDnComponent,
    FinanceInvoiceAdjustComponent,
    FinancePaymentHistoryComponent,
    FinanceCnGeneratedComponent,
    SalesDashboardPopupComponent,
    SaleMedicinesComponent,
    CustomizeConfirmComponent,
    LogisticsCoinsEditComponent,
    CreateSessionConfirmComponent,
    PrintBillWarningComponent
  ],
  entryComponents: [
    DialogSuccessComponent,
    DialogErrorComponent,
    DialogConfirmComponent,
    WmmPopupComponent,
    GenerateDnComponent,
    MissingPopupComponent,
    ExpiryComponent,
    WrongBilledComponent,
    RemoveMedConfirmComponent,
    SearchByRetailerComponent,
    SearchByOrderComponent,
    SearchByInvoiceComponent,
    RemoveBounceMedComponent,
    RemoveMedConfirmComponent,
    BlockUserComponent,
    ImageComponent,
    UnblockUserComponent,
    CommentComponent,
    LogisticsCollectComponent,
    CollectRetailerOutstandingComponent,
    OutstandingPaymentHistoryComponent,
    OutstandingDeRetailerInvoiceComponent,
    OutstandingBillingInvoiceComponent,
    ConnectionLostComponent,
    ConfirmDisablePlatformComponent,
    ConfirmSingleSoCancelComponent,
    ConfirmMultipleSoCancelComponent,
    ConfirmRetrieveComponent,
    GeneratePoComponent,
    ConfirmMedicineDeleteComponent,
    ConfirmMedicineDeleteComponent,
    CommonConfirmComponent,
    AdnUploadErrorsComponent,
    AdnUploadErrorsComponent,
    LiveSiOrderComponent,
    AddMedicineSiComponent,
    PastSiOrderComponent,
    AddNewMedicineToMapComponent,
    AddNewCompositionComponent,
    AddNewManufactureComponent,
    AddNewDiseaseComponent,
    ConfirmComponent,
    ErrorListComponent,
    UploadCreditErrorComponent,
    ConfirmComponent,
    CreateTicketComponent,
    TicketInstrutionsComponent,
    CreateHelpExecutiveComponent,
    MultipleImagesComponent,
    UploadCreditBillErrorComponent,
    ConfirmReasonComponent,
    AssignExpiryComponent,
    CaCollectionComponent,
    MappingApproveConfirmComponent,
    WarehouseGenericConfirmComponent,
    DrOpenItemsSlotFilterComponent,
    DrOpenItemsSpokeFilterComponent,
    AddOpenMedicineWarehouseComponent,
    AddPickItemComponent,
    MultipleImagesComponent,
    AdnMapConfirmComponent,
    AdnUploadCsvComponent,
    CollectSignedSlipComponent,
    AddBannerComponent,
    CollectAcknowledgementSlipComponent,
    SendExpiryItmesToDnComponent,
    AddItemsFilterComponent,
    GenerateRdcnComponent,
    GstResponseComponent,
    FinanceInvoiceDetailsComponent,
    FinanceDistributorDetailsComponent,
    FinanceDisputeComponent,
    FinanceInvoiceListComponent,
    FinanceDnComponent,
    FinanceInvoiceAdjustComponent,
    FinancePaymentHistoryComponent,
    FinanceCnGeneratedComponent,
    SalesDashboardPopupComponent,
    SaleMedicinesComponent,
    CustomizeConfirmComponent,
    CreateSessionConfirmComponent,
    PrintBillWarningComponent
  ]
})
export class DialogModule {}
