import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogModelSearchByInvoice } from './model';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { AdminService } from 'src/app/core/services/api/admin.service';
import { SupplyService } from 'src/app/core/services/api/supply.service';
import { accessList } from 'src/globalvariables';

@Component({
  selector: 'app-search-by-invoice',
  templateUrl: './search-by-invoice.component.html',
  styleUrls: ['./search-by-invoice.component.scss']
})
export class SearchByInvoiceComponent implements OnInit {
  orders = [];
  retailerData;
  showRetailerData = false;
  showAllOrders = false;
  showOtherOrders = false;
  otherOrders = [];
  printStatus = {
    id: '',
    status: true
  }
  status = false;
  statusNew = false;

  invoiceNumber = '';
  orderedBilledData = [];
  showDetails = false;
  adminNumber = ''
  accessList = {}
  constructor(
    public dialogRef: MatDialogRef<SearchByInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelSearchByInvoice,
    private adminService: AdminService,
    private popupService: PopupService,
    private supplyService: SupplyService) {

  }

  ngOnInit(): void {
    this.accessList = accessList
    this.getData();

  }
  copyRet(code) {
    navigator.clipboard.writeText(code)
  }
  getData() {
    this.popupService.openProgress();
    const params = {
      invoiceNumber: this.data.invoiceNumber
    };
    this.adminService.globalSearchByInvoiceNumber(params).subscribe(
      response => {
        this.orders = response.data;
        this.orders.map(order => {
          order.showTrack = false;
        })
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  getRetailerData(code) {
    this.showOtherOrders = false;
    if (!this.showRetailerData) {
      this.popupService.openProgress();
      this.retailerData = {};
      const params = {
        retailerCode: code
      };
      this.adminService.globalSearchGetRetailerData(params).subscribe(
        response => {
          this.retailerData = response.data;
          this.showRetailerData = true;
          this.orders.map(order => {
            order.showTrack = false;
          })
          this.popupService.closeProgress();
        },
        error => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      )
    } else {
      this.showRetailerData = false;
    }
    this.orders.map(order => {
      order.showTrack = false;
    })
    this.showAllOrders = false;
  }

  getOtherOrdersTrack(id) {
    this.popupService.openProgress();
    this.showAllOrders = false;
    this.showRetailerData = false;
    this.adminService.globalSearchByOrderId({ orderId: id }).subscribe(
      response => {
        this.showOtherOrders = true;
        this.otherOrders = response.data;
        this.otherOrders.map(order => {
          order.showTrack = true;
        })
        console.log("other orders are " + this.otherOrders);
        this.popupService.closeProgress();
      },
      error => {
        this.showOtherOrders = false;
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  getAllOrders() {
    this.showAllOrders = true;
    this.orders.map(order => {
      order.showTrack = false;
    });
    this.showOtherOrders = false;
    this.otherOrders.map(order => {
      order.showTrack = false;
    });
    this.showRetailerData = false;
  }

  removeAllOrders() {
    this.showAllOrders = false;
    this.orders.map(order => {
      order.showTrack = false;
    });
    this.showOtherOrders = false;
    this.otherOrders.map(order => {
      order.showTrack = false;
    });
    this.showRetailerData = false;
  }

  getTrack(i) {
    this.showOtherOrders = false;
    this.orders[i].showTrack = !this.orders[i].showTrack;
    this.showRetailerData = false;
    this.showAllOrders = false;
  }

  closeTrack(index) {
    this.orders[index].showTrack = false;
    this.showOtherOrders = false;
  }

  cancel() {
    this.dialogRef.close(false);
  }



  //  open order details dialog on click orderid
  openOrdersDialog(orderId, test) {
    console.log(orderId)
    this.popupService.openProgress();
    const params = {
      retrieveOrderId: orderId
    }
    this.supplyService.getBuyerInvoiceDetail(params).subscribe(
      response => {
        response.data.test = test
        this.popupService.openLiveSiOrders(response.data).afterClosed().subscribe(
          response => {


          }
        )
      },
      error => {
        this.popupService.closeProgress()
        this.popupService.openError(error.error.error.error);
      }
    )

  }

  openOrderedBilled(invNo) {
    // this.dialogRef.close(invoiceNumber);
    this.popupService.openProgress();
    const params = {
      invoiceNumber: invNo
    };
    this.invoiceNumber = invNo;
    this.adminService.globalSearchGetOrderedBilledData(params).subscribe(
      response => {
        this.orderedBilledData = response.data;
        this.orderedBilledData.map(item => {
          if (!item.orderCompareDetails) {
            item.orderCompareDetails = {};
          }
          if (!item.purchaseCompareDetails) {
            item.purchaseCompareDetails = {};
          }
          if (!item.billCompareDetails) {
            item.billCompareDetails = {};
          }
        })
        this.showDetails = true;
        this.popupService.closeProgress();
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  printBill(id, status) {
    this.status = true
    this.printStatus = {
      id: id,
      status: status
    }
    setTimeout(() => {
      this.status = false;
    }, 2000)

  }

  printBillNew(id, statusNew) {
    this.statusNew = true
    this.printStatus = {
      id: id,
      status: statusNew
    }

  }

  setStatusNew(event) {
    this.statusNew = event

  }

  generateIRN(orderId) {
    this.popupService.openProgress();
    const params = {
      lstOrderRetrieveIds: [orderId]
    };
    this.supplyService.generateIRN(params).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess("Success");
        this.downloadCSV(response.data);
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  generateIRNV2(orderId) {
    this.popupService.openProgress();
    const params = {
      lstOrderRetrieveIds: [orderId]
    };
    this.supplyService.generateIRNV2(params).subscribe(
      response => {
        this.popupService.closeProgress();
        this.popupService.openSuccess("Success");
        this.downloadCSV(response.data);
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  generateEwayBill(orderID) {
    this.popupService.openEwayBill({ invoiceNumber: this.data.invoiceNumber }).afterClosed().subscribe((res) => {
      if (res) {
        const body = {
          invoiceNumber: this.data.invoiceNumber,
          transportId: res.transportId,
          vehicleType: res.vehicleType,
          vehicleNumber: res.vehicleNumber,
          transporterName: res.transporterName
        }
        this.popupService.openProgress()
        this.supplyService.generateEwayBill(body).subscribe((res) => {
          console.log({ res })
          this.popupService.closeProgress()
          this.popupService.openSuccess(res || "Success")
        }, error => {
          this.popupService.closeProgress()
          this.popupService.openError("Something went wrong")
        })
      }
    })
  }

  // Download Generated IRN
  downloadCSV = (generatedIRN) => {
    const fields = [
      "orderRetrieveId",
      "invoiceNumber",
      "status",
      "irnNumber",
      "errorMessage",
    ];
    const header = [
      "orderRetrieveId",
      "invoiceNumber",
      "status",
      "irnNumber",
      "errorMessage",
    ];
    const replacer = (key, value) => (value === null ? null : value);

    const csv = generatedIRN.map((row) =>
      fields.map((fieldName) => row[fieldName], replacer).join(",")
    );

    // add headers
    csv.unshift(header.join(","));
    const csvArray = csv.join("\n");

    // generate file
    const a = document.createElement("a");
    const blob = new Blob([csvArray], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // download file
    a.href = url;
    a.download = "GENERATED_IRN_" + new Date().toLocaleDateString("en-GB");
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  viewBill(id) {
    this.popupService.openViewBill({ id }).afterClosed().subscribe((res) => {

    })
  }
}
