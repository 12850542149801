import { Component, OnInit, Inject } from '@angular/core';
import { DialogModelSalesDashboard } from './model';
import { PopupService } from 'src/app/core/services/popup/popup.service';
import { AdminService } from 'src/app/core/services/api/admin.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HubService } from 'src/app/core/services/api/hub.service';
import { I } from '@angular/cdk/keycodes';


export interface hub {
  hubCode: string;
  id: number
}
@Component({
  selector: 'app-sales-dashboard-popup',
  templateUrl: './sales-dashboard-popup.component.html',
  styleUrls: ['./sales-dashboard-popup.component.scss']
})
export class SalesDashboardPopupComponent implements OnInit {

  saleTypes = ['SALE', 'DealOfTheDay', 'FlashSale', 'Special_Rate', 'BulkBuy', 'FactoryToPharmacy']
  filteredSaleTypes = ['SALE', 'DealOfTheDay', 'FlashSale', 'Special_Rate', 'BulkBuy', 'FactoryToPharmacy']
  selectedSaleType = null;
  hubs = [];
  filteredHubs = [];
  retrievalCodes = [];
  saleName = null;
  medicineTag = null;
  description = null;
  startTime = null;
  todayDate = null;
  endTime = null;
  createWidget = 'yes'
  selectedHubs = [{ hub: { hubCode: null, id: null }, selectRsp: [], allRsps: [] }]
  selectedHubsIntitalData = []
  selectedHubArray = []
  editStatus = {
    saleName: true,
    medicineTag: true,
    description: true,
    hubSection: true,
    saleType: true,
    startTime: true,
    endTime: true,
  }
  csvFile = null;
  saleData = null;
  minEndTime = null;
  maxStartTime = null
  enableEdit = false
  editCsv = null;
  deleteCsv = null;
  showDeepLinkMessage = false;
  userAdminAccess = false;
  constructor(
    public dialogRef: MatDialogRef<SalesDashboardPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelSalesDashboard,
    private adminService: AdminService,
    private popupService: PopupService,
    private hubService: HubService
  ) { }

  ngOnInit(): void {
    let userData = JSON.parse(localStorage.getItem('userData'))
    this.userAdminAccess = userData.user.roles.some((role) => role.id === 'SALE_ADMIN')
    let tdDate = new Date();
    let tdDateString = tdDate.getFullYear() + "-" + ("0" + (tdDate.getMonth() + 1)).slice(-2) + "-" + ("0" + tdDate.getDate()).slice(-2) + "T" + ('0' + tdDate.getHours()).slice(-2) + ":" + ('0' + tdDate.getMinutes()).slice(-2)
    this.todayDate = tdDateString
    if (this.data.data.action === 'new') {
      this.popupService.openProgress()
      this.getHubs(true)
    } else {
      this.enableEdit = true
      this.editStatus = {
        saleName: false,
        medicineTag: false,
        description: false,
        hubSection: false,
        saleType: false,
        startTime: false,
        endTime: false,
      }
      this.getHubs(true)
      this.getSaleData()
    }

  }

  editSale() {

  }
  save(type) {
    this.popupService.openProgress()
    let body = {
      "dataString": null,
      "saleId": this.data.data.saleData.saleId,
      "type": "string"
    }
    if (type === 'saleName') {
      body.dataString = this.saleName
      body.type = "SALE_NAME"
    } else if (type === 'medicineTag') {
      body.dataString = this.medicineTag
      body.type = "SALE_TAG"
    } else if (type === 'description') {
      body.dataString = this.description
      body.type = "DESCRIPTION"
    } else if (type === 'saleType') {
      body.dataString = this.selectedSaleType
      body.type = "SALE_TYPE"
    } else if (type === 'startTime') {
      body.dataString = new Date(this.startTime).getTime()
      body.type = "SALE_START_TIME"
    } else if (type === 'endTime') {
      body.dataString = new Date(this.endTime).getTime()
      body.type = "SALE_END_TIME"
    }

    this.adminService.editSale({}, body).subscribe((res) => {
      if (type === 'saleName') {
        this.editStatus.saleName = false
        this.saleData.saleName = this.saleName

      } else if (type === 'medicineTag') {
        this.editStatus.medicineTag = false
        this.saleData.saleTag = this.medicineTag

      } else if (type === 'description') {
        this.editStatus.description = false
        this.saleData.description = this.description

      } else if (type === 'saleType') {
        this.editStatus.saleType = false
        this.saleData.saleType = this.selectedSaleType

      } else if (type === 'startTime') {
        this.editStatus.startTime = false;

      } else if (type === 'endTime') {
        this.editStatus.endTime = false;

      }
      this.popupService.closeProgress()
      this.popupService.openSuccess(res.data)
    },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      })


  }
  cancelSave(type) {
    if (type === 'saleName') {
      this.editStatus.saleName = false
      this.saleName = this.saleData.saleName
    } else if (type === 'medicineTag') {
      this.editStatus.medicineTag = false
      this.medicineTag = this.saleData.saleTag
    } else if (type === 'description') {
      this.editStatus.description = false
      this.description = this.saleData.description
    } else if (type === 'saleType') {
      this.editStatus.saleType = false
      this.selectedSaleType = this.saleData.saleType
    } else if (type === 'startTime') {
      this.editStatus.startTime = false;
      let StDate = new Date(this.saleData.startTime);
      let StDateString = StDate.getFullYear() + "-" + ("0" + (StDate.getMonth() + 1)).slice(-2) + "-" + ("0" + StDate.getDate()).slice(-2) + "T" + ('0' + StDate.getHours()).slice(-2) + ":" + ('0' + StDate.getMinutes()).slice(-2)
      this.startTime = StDateString
    } else if (type === 'endTime') {
      this.editStatus.endTime = false;
      let EnDate = new Date(this.saleData.endTime);
      let EnDateString = EnDate.getFullYear() + "-" + ("0" + (EnDate.getMonth() + 1)).slice(-2) + "-" + ("0" + EnDate.getDate()).slice(-2) + "T" + ('0' + EnDate.getHours()).slice(-2) + ":" + ('0' + EnDate.getMinutes()).slice(-2)
      this.endTime = EnDateString
    } else if (type === "hubSection") {
      this.editStatus.hubSection = false
      this.selectedHubs = JSON.parse(JSON.stringify(this.selectedHubsIntitalData))
      this.selectedHubArray = this.selectedHubs.map((obj) => {
        return obj.hub.hubCode
      })
    }
  }
  getSaleData() {
    this.popupService.openProgress()
    let params = {
      saleId: this.data.data.saleData.saleId
    }
    this.adminService.getSaleById(params).subscribe((res) => {
      this.popupService.closeProgress()
      let data = res.data
      this.saleData = JSON.parse(JSON.stringify(res.data))
      this.saleName = data.saleName
      this.medicineTag = data.saleTag
      this.selectedSaleType = data.saleType
      this.description = data.description

      let StDate = new Date(data.startTime);
      let StDateString = StDate.getFullYear() + "-" + ("0" + (StDate.getMonth() + 1)).slice(-2) + "-" + ("0" + StDate.getDate()).slice(-2) + "T" + ('0' + StDate.getHours()).slice(-2) + ":" + ('0' + StDate.getMinutes()).slice(-2)
      this.startTime = StDateString
      let EnDate = new Date(data.endTime);
      let EnDateString = EnDate.getFullYear() + "-" + ("0" + (EnDate.getMonth() + 1)).slice(-2) + "-" + ("0" + EnDate.getDate()).slice(-2) + "T" + ('0' + EnDate.getHours()).slice(-2) + ":" + ('0' + EnDate.getMinutes()).slice(-2)
      this.endTime = EnDateString
      this.minEndTime = this.startTime
      this.selectedHubs = []
      data.salesConfigList.forEach((sale) => {
        let index = null
        for (let i = 0; i < this.selectedHubs.length; i++) {
          if (this.selectedHubs[i].hub.hubCode === sale.hubCode) {
            index = i
            break;
          }
        }

        if (index === null) {

          let obj = { hub: { hubCode: null, id: null }, selectRsp: [], allRsps: [] }
          obj.hub = { hubCode: sale.hubCode, id: sale.hubId }
          // const params = {
          //   hubId:sale.hubId
          // };
          // this.hubService.getRetrievalCodes(params).subscribe(
          //   response => {
          //       obj.allRsps=response.data
          obj.selectRsp.push(sale.retrievalSpokeCode)

          this.selectedHubs.push(obj)
          this.selectedHubArray.push(obj.hub.hubCode)
          //   },
          //   error => {
          //     this.popupService.closeProgress();
          //     this.popupService.openError(error.error.error.error);
          //   }
          // )
        } else {
          this.selectedHubs[index].selectRsp.push(sale.retrievalSpokeCode)
        }

      })

      this.selectedHubsIntitalData = JSON.parse(JSON.stringify(this.selectedHubs))

      this.selectedHubs.forEach((obj, i) => {
        const params = {
          hubId: obj.hub.id
        };
        this.hubService.getRetrievalCodes(params).subscribe(
          response => {
            obj.allRsps = response.data
            obj.allRsps.unshift({ retrievalSpokeCode: 'Select_All', id: 'all' })
            this.selectedHubsIntitalData[i].allRsps = [...obj.allRsps]
          },
          error => {
            this.popupService.closeProgress();
            this.popupService.openError(error.error.error.error);
          }
        )
      })


      console.log(this.selectedHubs)

      this.popupService.closeProgress()
    },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      })
  }

  searchSaleType(event) {
    let data = [...this.saleTypes]
    this.filteredSaleTypes = data.filter((item) => item.toLowerCase().includes(event.target.value.toLowerCase()))
  }

  displayFn(hub: hub): string {
    return hub && hub.hubCode ? hub.hubCode : '';
  }

  // get hubs
  getHubs(closeProgress) {
    this.hubService.getHubs().subscribe(
      response => {
        this.hubs = response.body.data;
        this.filteredHubs = [...this.hubs]
        this.popupService.closeProgress()
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  searchHub(event) {
    let data = [...this.hubs]
    this.filteredHubs = data.filter((item) => item.hubCode.toLowerCase().includes(event.target.value.toLowerCase()))

  }

  hubsFocus(index) {
    this.filteredHubs = [...this.hubs]
  }

  changeHub(index, event) {
    // this.popupService.openProgress();
    this.selectedHubs[index].hub = event;
    this.selectedHubArray[index] = event.hubCode
    this.getRetrievalCodes(true, index);
  }

  getRetrievalCodes(closeProgress, index) {
    const params = {
      hubId: this.selectedHubs[index].hub['id']
    };
    this.hubService.getRetrievalCodes(params).subscribe(
      response => {
        this.selectedHubs[index].allRsps = response.data
        this.selectedHubs[index].selectRsp = []
        this.selectedHubs[index].allRsps.unshift({ retrievalSpokeCode: 'Select_All', id: 'all' })
        if (closeProgress) {
          this.popupService.closeProgress();
        }
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }


  searchRsp(event, index) {
    // this.distributors.searchDistributors=this.distributors.allDistributors.filter((item,i)=>item.distributorName.toUpperCase().includes(event.target.value.toUpperCase()))
    this.selectedHubs[index].allRsps.forEach((item, i) => {
      if (!item.retrievalSpokeCode.toUpperCase().includes(event.target.value.toUpperCase())) {
        item.show = false
      } else {
        item.show = true
        console.log(item)
      }
    })
  }

  rspChange(item) {
    console.log(item)
    if (item.selectRsp.includes('Select_All')) {
      item.selectRsp = []
      item.allRsps.forEach((rsp) => {
        item.selectRsp.push(rsp.retrievalSpokeCode)
      })
    } else {
      item.selectRsp = []
    }


    console.log(item)

  }
  hubFocusOut(index) {
    (<HTMLInputElement>document.getElementById(`hub${index}`)).value = this.selectedHubs[index].hub.hubCode;
  }

  addHub() {
    this.selectedHubs.push({ hub: { hubCode: null, id: null }, selectRsp: [], allRsps: [] })
  }

  deleteHub(index) {
    this.selectedHubArray.splice(index, 1)
    this.selectedHubs.splice(index, 1)
  }
  closePopup() {
    this.dialogRef.close(false)
  }
  uploadFile(event) {
    this.csvFile = event?.target?.files[0]
  }

  editCSVChange(event) {
    this.popupService.openProgress()
    let params = {
      saleId: this.data.data.saleData.saleId
    }

    const formData = new FormData();
    formData.append("file", event?.target?.files[0])

    this.adminService.editSaleCSV(formData, params).subscribe((res) => {
      this.popupService.closeProgress();
      this.popupService.openSuccess('Success');
      (<HTMLInputElement>document.getElementById('file2')).value = "";
    },
      error => {
        (<HTMLInputElement>document.getElementById('file2')).value = "";
        this.popupService.closeProgress();
        var reader = new FileReader();
        reader.readAsText(error.error);
        reader.onload = () => {
          const result = String(reader.result)
          const obj = JSON.parse(result)
          console.log(obj)
          this.popupService.openError(obj.error.error);
        }
      })


  }

  deleteCSVChange(event) {
    this.popupService.openProgress()
    let params = {
      saleId: this.data.data.saleData.saleId
    }

    const formData = new FormData();
    formData.append("file", event?.target?.files[0])

    this.adminService.deleteSaleCSV(formData, params).subscribe((res) => {
      this.popupService.closeProgress();
      this.popupService.openSuccess('Success');
      (<HTMLInputElement>document.getElementById('file3')).value = "";
    },
      error => {
        (<HTMLInputElement>document.getElementById('file3')).value = "";
        this.popupService.closeProgress();
        var reader = new FileReader();
        reader.readAsText(error.error);
        reader.onload = () => {
          const result = String(reader.result)
          const obj = JSON.parse(result)
          console.log(obj)
          this.popupService.openError(obj.error.error);
        }
      })


  }

  saveHubConfigs() {
    this.popupService.openProgress()
    let salesConfigList = []
    let removedConfigList = []
    let allselectedRsps = []
    let emptyHubs = []
    this.selectedHubs.forEach((item, index) => {
      let addedItems = []
      let removedItems = []
      allselectedRsps.push(...item.selectRsp)
      !item.selectRsp.length && emptyHubs.push(index)
      if (item.selectRsp[0] === 'Select_All') {
        item.selectRsp.shift()
      }
      if (this.selectedHubsIntitalData[index]) {
        item.selectRsp.forEach((rsp) => {
          if (!this.selectedHubsIntitalData[index].selectRsp.includes(rsp)) {
            addedItems.push(rsp)
          }
        })
        this.selectedHubsIntitalData[index].selectRsp.forEach((rsp) => {
          if (!item.selectRsp.includes(rsp)) {
            removedItems.push(rsp)
          }
        })
      } else {
        addedItems = [...item.selectRsp]
      }

      if (addedItems.length) {
        addedItems.forEach((addeditem) => {
          let rspData = item.allRsps.filter((rsp) => rsp.retrievalSpokeCode === addeditem)
          let hubObj = {}
          hubObj['hubCode'] = item.hub.hubCode
          hubObj['hubId'] = item.hub.id
          hubObj['retrievalSpokeCode'] = rspData[0]['retrievalSpokeCode']
          hubObj['retrievalSpokeId'] = rspData[0]['id']
          salesConfigList.push(hubObj)
        })

      }
      if (removedItems.length) {
        removedItems.forEach((removedItem) => {
          let rspData = item.allRsps.filter((rsp) => rsp.retrievalSpokeCode === removedItem)
          let hubObj = {}
          hubObj['hubCode'] = item.hub.hubCode
          hubObj['hubId'] = item.hub.id
          hubObj['retrievalSpokeCode'] = rspData[0]['retrievalSpokeCode']
          hubObj['retrievalSpokeId'] = rspData[0]['id']
          removedConfigList.push(hubObj)
        })

      }
    })

    if (!allselectedRsps.length) {
      this.popupService.closeProgress()
      this.popupService.openError("Please select atleast one rsp")
    } else {
      let body = {
        saleId: this.data.data.saleData.saleId,
        "description": this.description,
        "endTime": new Date(this.endTime).getTime(),
        "saleName": this.saleName,
        "saleTag": this.medicineTag,
        "saleType": this.selectedSaleType,
        "startTime": new Date(this.startTime).getTime(),
        salesConfigListToAdd: salesConfigList,
        salesConfigListToRemove: removedConfigList
      }
      this.adminService.saveRSPSaleData(body).subscribe((res) => {
        this.popupService.closeProgress()
        this.popupService.openSuccess('Success')
        this.editStatus.hubSection = false;
        emptyHubs.forEach((index) => {
          this.selectedHubs.splice(index, 1)
        })
        this.selectedHubsIntitalData = JSON.parse(JSON.stringify(this.selectedHubs))
        this.selectedHubArray = this.selectedHubs.map((obj) => {
          return obj.hub.hubCode
        })
      },
        error => {
          this.popupService.closeProgress();
          this.popupService.openError(error?.error?.error?.error || 'Something went wrong');
        })
    }


  }

  launchSale() {
    let createStatus = true
    if (!this.selectedSaleType) {
      createStatus = false
      this.popupService.openError("Please select sale type")
    } else if (this.selectedSaleType == "SALE" && !this.saleName) {
      createStatus = false
      this.popupService.openError("Please enter sale name ")
    } else if (this.selectedSaleType == "SALE" && !this.medicineTag) {
      createStatus = false
      this.popupService.openError("Please enter medicine tag ")
    } else if (!this.startTime) {
      createStatus = false
      this.popupService.openError("Please select start date and time ")
    } else if (!this.endTime) {
      createStatus = false
      this.popupService.openError("Please select end date and time ")
    } else if (!this.csvFile) {
      createStatus = false
      this.popupService.openError("Please upload csv file ")
    } else if (!this.createWidget) {
      createStatus = false
      this.popupService.openError("Please select create widget ")
    }




    let obj = {
      "description": this.description,
      "endTime": new Date(this.endTime).getTime(),
      "saleName": this.saleName,
      "saleTag": this.medicineTag,
      "saleType": this.selectedSaleType,
      "salesConfigList": [],
      "addWidget": this.createWidget === 'yes' ? true : this.createWidget === 'no' ? false : false,
      "startTime": new Date(this.startTime).getTime()
    }

    console.log(obj)
    this.selectedHubs.forEach((item) => {
      if (item.hub.hubCode && item.selectRsp.length) {
        item.selectRsp.forEach((rsp) => {
          for (let i = 0; i < item.allRsps.length; i++) {
            if (rsp !== 'Select_All' && item.allRsps[i].retrievalSpokeCode === rsp) {
              let hubObj = {}
              hubObj['hubCode'] = item.hub.hubCode
              hubObj['hubId'] = item.hub.id
              hubObj['retrievalSpokeCode'] = item.allRsps[i].retrievalSpokeCode
              hubObj['retrievalSpokeId'] = item.allRsps[i].id
              obj.salesConfigList.push(hubObj)
              break;
            }
          }
        })
      } else {
        createStatus = false
        this.popupService.openError("Please select hub and rsps")
      }

    })
    const formData = new FormData();
    formData.append("file", this.csvFile)

    console.log(obj)
    const blob = new Blob([JSON.stringify(obj)], {
      type: 'application/json'
    });

    formData.append("salesRequest", blob);
    if (createStatus) {
      this.popupService.openProgress()
      this.adminService.createSale(formData, {}).subscribe((res) => {
        this.popupService.closeProgress();
        var reader = new FileReader();
        reader.readAsText(res.body);
        reader.onload = () => {
          const result = String(reader.result)
          const obj = JSON.parse(result)
          if (obj?.data?.uploadFileErrorResponse?.errorInInventoryFile) {
            this.popupService.openErrorList(obj?.data?.uploadFileErrorResponse?.errorInInventoryFile);
          }
          this.popupService.openSuccess('Success')
          setTimeout(() => {
            this.dialogRef.close(true)
          }, 2000)
        }
      },
        error => {

          this.popupService.closeProgress();
          var reader = new FileReader();
          reader.readAsText(error.error);
          reader.onload = () => {
            const result = String(reader.result)
            const obj = JSON.parse(result)
            this.popupService.openError(obj.error.error);


          }
        })
    }



  }


  deleteSale() {
    this.popupService.openCommonConfirmPopup("Do you want to delete sale").afterClosed().subscribe((res) => {
      if (res) {
        this.popupService.openProgress()
        this.adminService.deleteSale({ saleId: this.data.data.saleData.saleId }, {}).subscribe((res) => {
          this.popupService.closeProgress()
          this.popupService.openSuccess(res.data)
          setTimeout(() => {
            this.dialogRef.close(true)
          }, 1000)
        },
          error => {
            this.popupService.closeProgress();
            this.popupService.openError(error.error.error.error);
          })
      }
    })
  }

  viewSales() {
    this.popupService.openSaleMedicinesPopup({ saleId: this.data.data.saleData.saleId }).afterClosed().subscribe((res) => {

    })
  }

  // update start time
  updateStartTime() {
    this.minEndTime = this.startTime;
  }

  // update end time
  updateEndTime() {
    this.maxStartTime = this.endTime;
  }

  copyDeepLink() {
    navigator.clipboard.writeText(this.saleData.deepLink);
    this.showDeepLinkMessage = true
    setTimeout(() => {
      this.showDeepLinkMessage = false
    }, 3000)
  }

}
