import { Component, OnInit } from "@angular/core";
import { SupplyService } from "src/app/core/services/api/supply.service";
import { PopupService } from "src/app/core/services/popup/popup.service";

@Component({
  selector: "app-pob-dist-upload",
  templateUrl: "./pob-dist-upload.component.html",
  styleUrls: ["./pob-dist-upload.component.scss"],
})
export class PobDistUploadComponent implements OnInit {
  csvFiles = [];

  constructor(
    private popupService: PopupService,
    private supplyService: SupplyService
  ) { }

  ngOnInit(): void { }

  // download sample CSV file
  downloadSampleCSV(type: String) {
    this.popupService.openProgress();

    this.supplyService.getSamplePOBFile().subscribe((res) => {
      this.popupService.closeProgress();
      this.downloadSampleFile([], Object.values(JSON.parse(res.data)), []);
    });
  }

  downloadSampleFile(data, header, fields) {
    const replacer = (key, value) => (value === null ? "" : value);

    const csv = data.map((row) =>
      fields
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );

    // add headers
    csv.unshift(header.join(","));
    const csvArray = csv.join("\r\n");

    // generate file
    const a = document.createElement("a");
    const blob = new Blob([csvArray], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // download file
    a.href = url;
    a.download = `Sample_POB_Dist_CSV`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  selectCSV(event) {
    if (event?.target?.files?.length) {
      this.csvFiles[0] = event?.target?.files[0];
    } else {
      this.csvFiles = [];
      (<HTMLInputElement>document.getElementById("file")).value = "";
    }
  }

  uploadCSV() {
    if (this.csvFiles[0]) {
      this.popupService.openProgress();

      const formData = new FormData();
      formData.append("multipartFile", this.csvFiles[0], this.csvFiles[0].name);

      this.supplyService.uploadPOBDistributor(formData).subscribe(
        (response) => {
          (<HTMLInputElement>document.getElementById("file")).value = "";
          this.csvFiles = [];
          this.popupService.closeProgress();
          this.popupService.openSuccess("Success");
        },
        (error) => {
          (<HTMLInputElement>document.getElementById("file")).value = "";
          this.csvFiles = [];
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      );
    }
  }
}
