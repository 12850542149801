import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA ,MatDialogRef} from "@angular/material";
import { AdminService } from "src/app/core/services/api/admin.service";
import { SupplyService } from "src/app/core/services/api/supply.service";
import { PopupService } from "src/app/core/services/popup/popup.service";
import { DialogManualCN } from "../manual-cn-popup/model";

@Component({
  selector: "app-manage-cn-popup",
  templateUrl: "./manage-cn-popup.component.html",
  styleUrls: ["./manage-cn-popup.component.scss"],
})
export class ManageCnPopupComponent implements OnInit {
  order;
  wholeDetails = [];
  retailerInfo;
  saveoInfo;
  pageDetailLimit;
  medSizeArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  showCross = true;
  showBill = false;
  cnData = [];
  dlNo=[];
  distributorInfo=null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogManualCN,
    public popupService: PopupService,
    private adminService: AdminService,
    private supplyService:SupplyService,
    public dialogRef: MatDialogRef<ManageCnPopupComponent>,
  ) // private cdr: ChangeDetectorRef,
  {}

  ngOnInit(): void {
    this.cnData = this.data.data.cnData;
    if(this.data.data?.debitNote){
       this.getDebitNoteDetails(this.data.data.expiry)
    }else{
      this.getCreditNoteDetails();
    }
  }

  cancel(){
    this.dialogRef.close(false);
  }
  // ngAfterViewInit(){
  //   this.cdr.detectChanges()
  // }

  // api to get debit note details 
  getDebitNoteDetails(expiry) {
    this.popupService.openProgress()
    const params ={
      debitNoteId: this.data.data?.debitNoteId,
      pending: true
    }
    let api;
    if(expiry){
      const params ={
        id: this.data.data?.debitNoteId
      }
      api=this.supplyService.getExpiryDnDetails(params)
    }else{ 
      api = this.supplyService.getDebitNoteDetailsPage(params)
    }
    api.subscribe(
      response => {
        this.wholeDetails = response.data;
        this.wholeDetails[0].show = true;
        this.pageDetailLimit = this.wholeDetails.length;
        this.getDistInfo(true, this.wholeDetails[0].invoiceDetail.value.distributorCode);
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }
  getDistInfo(closeProgress, distId) {
    const params = {
      distributorId: distId
    };
    this.adminService.getDistributorInfoForDebitNote(params).subscribe(
      response => {
        this.distributorInfo = response.data;
        this.getSaveoInfo('DN',this.data.data?.debitNoteId);
      },
      error => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    )
  }

  // api to get the credit note details
  getCreditNoteDetails() {
     if(this.data.data?.expiry){
      this.popupService.openProgress();
      const params = {
        id: this.data.data.creditNoteId
      };
      this.supplyService.getExpiryCreditNoteDetails(params).subscribe(
        response => {
          this.wholeDetails = response.data;
          this.pageDetailLimit = this.wholeDetails.length;
          this.getUserInfo();
        },
        error => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      )
    }else if(this.data.data?.type==="RDCN"){
      const params = {
        id: this.data.data.creditNoteId
      };
      this.supplyService.getCNDetailsForRDCN(params).subscribe(
        response => {
          this.wholeDetails = response.data;
          this.pageDetailLimit = this.wholeDetails.length;
          this.getUserInfo();
        },
        error => {
          this.popupService.closeProgress();
          this.popupService.openError(error.error.error.error);
        }
      )
    }else{
      let params = {
        creditNoteId: this.data.data.creditNoteId,
      };
     
      if(this.data.data.type==="LOGISTICS_RETURN"){
          params['cnInitiatedId']=this.data.data.creditNoteId
          params['isInitiated']=this.data.data?.isInitiated
      }
      this.popupService.openProgress();
      this.adminService.getCreditNoteDetails(params).subscribe((response) => {
        this.wholeDetails = response.data;
        this.pageDetailLimit = this.wholeDetails.length;
        this.getUserInfo();
      });
    }
    

    
  }

  // get billing info related to the user
  getUserInfo() {
    const params = {
      retailerCode: this.data?.data?.retailerCode? this.data?.data?.retailerCode:this.wholeDetails[0]?.invoiceDetail?.value?.retailerCode,
    };
    this.adminService.getBuyerInfo(params).subscribe(
      (response) => {
        this.retailerInfo = response.data;
        let type;
        if(this.data?.data?.type==="LOGISTICS_RETURN"){
            type="CN"
        }else{
          type=this.data?.data?.type || 'CN'
        }
        this.getSaveoInfo(type,this.data.data.creditNoteId);
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }
  

  // get billing info related to saveo
  getSaveoInfo(type,id) {
    const params = {
      type: type,
      id: id,
    };
    this.adminService.getSaveoInfoForCNDN(params).subscribe(
      (response) => {
        this.saveoInfo = response.data;
        this.popupService.closeProgress();
        this.showBill = true;
        this.dlNo[0]=this.saveoInfo.dlNo.split(' ')[0];
        this.dlNo[1]=this.saveoInfo.dlNo.split(' ')[1];
        this.dlNo[2]=this.saveoInfo.dlNo.split(' ')[2];
      },
      (error) => {
        this.popupService.closeProgress();
        this.popupService.openError(error.error.error.error);
      }
    );
  }

  // handler to print the bill
  // handler to print the current screen
  handlePrintScreen(download=false) {
    setTimeout(() => {
      let popupWindow;
      var winHead = document.getElementsByTagName("head")[0];
      var style = document.createElement("style");
      style.type = "text/css";
      style.media = "print";

        style.appendChild(
          document.createTextNode(
            `@page {size: A4 landscape; margin:6mm;} 
            .bg-highlight {
              background-color: #232324c5 !important;
              color: white !important;
              margin: 0px 5px !important;
              -webkit-print-color-adjust: exact; 
            }`
          )
        );
     
      let findChild = (idOfElement) => {
        let elements = document.getElementsByClassName(idOfElement);
        for (var i = 0; i < elements.length; i++) {
              let element =elements.item(i)
              let parentSvg=element?.querySelector(`svg`)
         let childSvg= parentSvg?.querySelector(`svg`)
         const attr = childSvg?.getAttributeNode("shape-rendering");
         childSvg?.removeAttributeNode(attr);
          }
      }
      findChild('irnSvg')
      winHead.appendChild(style)
     
      if(!download){
        let page = document.getElementById("printable").innerHTML;
        popupWindow = window.open(
          "",
          "_blank",
          "width=auto,height=auto,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
        );
        popupWindow.document.open();
        popupWindow.document.write(
          '<html style="width: 100%;s">' +
            winHead.innerHTML +
            '<body onload="window.print()" class="body"><div class="full-page table-height">' +
            page +
            "</div></body></html>"
        );
        popupWindow.document.close();
      }else{
        let page='<!DOCTYPE html><html id="html">' + winHead.innerHTML + '<body class="body"><div id="full-page1" class="full-page table-height">' + document.getElementById('printable').innerHTML + '</div></body></html>'
        // var compressor = new LZUTF8.Compressor();
        
        let body={
          "action":"pdfBills",
          "content":page
      }
       
        this.popupService.openProgress()
         this.supplyService.getPDFBill(body).subscribe((res)=>{
          const url = window.URL.createObjectURL(new Blob([new Uint8Array(res.data.data).buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.wholeDetails[0]['invoiceDetail']['value']['invoiceNumber']}.pdf`);
          document.body.appendChild(link);
          link.click();
          this.popupService.closeProgress();
         },
         error => {
         
          this.popupService.closeProgress();
          this.popupService.openError('Download Bill Failed');
        })
      }
     
    }, 1);
  }
}
