import { Component, OnInit, Inject } from "@angular/core";
import { DialogModelLogisticsSessions } from "./model";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PopupService } from "../../../core/services/popup/popup.service";
import { FileUploadService } from "../../../core/services/api/file-upload.service";
import { LogisticsService } from "../../../core/services/api/logistics.service";

@Component({
  selector: "app-logistics-sessions-proceed",
  templateUrl: "./logistics-sessions-proceed.component.html",
  styleUrls: ["./logistics-sessions-proceed.component.scss"],
})
export class LogisticsSessionsProceedComponent implements OnInit {
  coinSessionSelect = "";
  totalCashBundlesAmount = 0;
  cashBundleNames: any = [];
  cashReceiptNumber = "";
  cashBundleReceipt: any = "";
  cashBundleReceiptURL = "";
  totalCoinBundlesAmount = 0;
  coinBundleNames: any = [];
  utrReconcilationAmount = 0;
  utrSearchInput = "";
  utrData = {
    page: 1,
    data: [],
    size: 0,
    selectedUtr: null,
  };
  coinsToCashData = {
    "2000": 0,
    "500": 0,
    "200": 0,
    "100": 0,
    "50": 0,
    "20": 0,
    "10": 0,
    "5": 0,
  };
  coinBundlesUUIDS: any = [];
  objectkeys = Object.keys;
  constructor(
    public dialogRef: MatDialogRef<LogisticsSessionsProceedComponent>,
    public popservice: PopupService,
    public fileUploadService: FileUploadService,
    public logisticService: LogisticsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogModelLogisticsSessions
  ) { }

  ngOnInit(): void {
    // this.data=this.data.data
    if (this.data?.data?.type === "Cash Bundle") {
      this.totalCashBundlesAmount = 0;
      this.cashBundleNames = [];
      this.data.data.bundles.forEach((item) => {
        this.totalCashBundlesAmount =
          this.totalCashBundlesAmount + +item.cashBundleDetails.totalCashAmount;
        this.cashBundleNames.push(item.cashBundleDetails.bundleName);
      });
    } else if (this.data?.data?.type === "Coin Bundle") {
      this.totalCoinBundlesAmount = 0;
      this.coinBundleNames = [];
      this.coinBundlesUUIDS = []
      this.data.data.bundles.forEach((item) => {
        this.totalCoinBundlesAmount =
          this.totalCoinBundlesAmount + (+item.coinBundleDetails.totalCoinAmount);
        this.coinBundleNames.push(item.coinBundleDetails.bundleName);

        this.coinBundlesUUIDS.push(item.coinBundleDetails.bundleUUID)
      });
      this.utrReconcilationAmount = this.totalCoinBundlesAmount;
    }
  }

  uploadImage(event) {
    this.popservice.openProgress();
    this.cashBundleReceipt = event?.target?.files[0];
    const formData = new FormData();
    formData.append(
      "file",
      this.cashBundleReceipt,
      this.cashBundleReceipt.name
    );

    this.fileUploadService.uploadBannerImage(formData).subscribe(
      (response) => {
        this.popservice.closeProgress();
        this.cashBundleReceiptURL = response.data;
      },
      (error) => {
        this.popservice.closeProgress();
        this.popservice.openError(
          error?.error?.error?.error || "Something went wrong"
        );
      }
    );
  }

  cashBundleSubmit() {
    this.dialogRef.close({
      url: this.cashBundleReceiptURL,
      receipt: this.cashReceiptNumber,
      totalAmount: this.totalCashBundlesAmount
    });
  }
  close() {
    this.dialogRef.close(null);
  }

  editCoins() {
    this.popservice
      .openLogisticsCoinsEdit(this.data.data)
      .afterClosed()
      .subscribe((res) => { });
  }

  goto(type) {
    this.dialogRef.close(type);
  }

  utrKeyup(event) {
    if (event.keyCode === 13) {
      this.utrSearch();
    }
  }

  // Get UTR Suggestions for each payment
  utrSearch() {
    this.popservice.openProgress();

    let params = {
      page: this.utrData.page,
      utrno: this.utrSearchInput,
    };

    this.logisticService.getAllBankStatementWithUTRNo(params).subscribe(
      (resp) => {
        this.popservice.closeProgress();
        this.utrData.data = resp.data.bankStatementResponseList;
        this.utrData.size = resp.data.count;
      },
      (error) => {
        this.popservice.closeProgress();
        this.popservice.openError(error?.error?.error?.error || 'Something went wrong');
      }
    );
  }

  previousPage() {
    this.utrData.page = this.utrData.page - 1;
    this.utrSearch();
  }

  nextPage() {
    this.utrData.page = this.utrData.page + 1;
    this.utrSearch();
  }

  utrSelect(item) {
    this.utrData.selectedUtr = item;
  }

  submit(type) {
    let retData = localStorage.getItem("userData");
    let errorState = false
    let params = {
      reconcileVia: type === "cash" ? "CASH" : "UTR",
      retailerCode: JSON.parse(retData).user.retailerCode,
    };
    let body = {
      bankStatementId: 0,
      bundleAmount: this.totalCoinBundlesAmount,
      bundleRequest: {
        bundleUUIDList: this.coinBundlesUUIDS,
        sessionUUIDList: [],
      },
      coinToCashNotesRequest: {
        cash10: 0,
        cash100: 0,
        cash20: 0,
        cash200: 0,
        cash2000: 0,
        cash5: 0,
        cash50: 0,
        cash500: 0,
      },
      utrNumber: "",
    };
    this.data.data.bundle
    if (type === "cash") {
      let total = 0;
      Object.keys(this.coinsToCashData).forEach((key) => {
        total = total + +this.coinsToCashData[key] * +key;
      });

      if (total !== this.totalCoinBundlesAmount) {
        this.popservice.openError(
          "Total denomination amount should be equal to total coin bundles amount"
        );
        errorState = true
      } else {
        body.coinToCashNotesRequest.cash10 = this.coinsToCashData['10'] || 0
        body.coinToCashNotesRequest.cash100 = this.coinsToCashData['100'] || 0
        body.coinToCashNotesRequest.cash20 = this.coinsToCashData['20'] || 0
        body.coinToCashNotesRequest.cash200 = this.coinsToCashData['200'] || 0
        body.coinToCashNotesRequest.cash2000 = this.coinsToCashData['2000'] || 0
        body.coinToCashNotesRequest.cash5 = this.coinsToCashData['5'] || 0
        body.coinToCashNotesRequest.cash50 = this.coinsToCashData['50'] || 0
        body.coinToCashNotesRequest.cash500 = this.coinsToCashData['500'] || 0

      }
    } else if (type === 'utr') {
      body.bankStatementId = this.utrData.selectedUtr?.utrNoId
      body.utrNumber = this.utrData.selectedUtr?.utrnumber
    }


    if (!errorState) {
      this.popservice.openProgress()
      this.logisticService.reconcileCoinBundle(body, params).subscribe((res) => {
        this.popservice.closeProgress();
        this.popservice.openSuccess(res.data)
        setTimeout(() => {
          this.dialogRef.close(true);
        }, 400)
      },
        (error) => {
          this.popservice.closeProgress();
          this.popservice.openError(error?.error?.error?.error || 'Something went wrong');
        })
    }



  }

  reconcileReceipt() {
    this.popservice.openProgress()
    let params = {
      utrNumber: this.utrData.selectedUtr?.utrnumber,
      bankStatementId: this.utrData.selectedUtr?.utrNoId,
      totalBundleAmount: this.data.data.bundles.cashAmount
    }
    let body = this.data.data.bundles.bundleUUIDList
    this.logisticService.reconcileReceipt(body, params).subscribe((res) => {
      this.popservice.closeProgress();
      this.popservice.openSuccess(res.data)
      setTimeout(() => {
        this.dialogRef.close(true);
      }, 400)
    },
      (error) => {
        this.popservice.closeProgress();
        this.popservice.openError(error?.error?.error?.error || 'Something went wrong');
      })
  }
}
